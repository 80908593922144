import { useMemo } from 'react';

import { useBrandAccentColorContext } from './brandContext';
import { FlairColor, getFlairColor } from './colors';
import { useFlairColorContext } from './colors/FlairColorContextProvider';
import { Display } from './styles/display';
import { FocusOutlineProps } from './styles/focusOutlines';
import { IStyle, ITheme, IsFocusVisibleClassName } from './styling';
import { Themed } from './theme';
import { FUI9ColorToken, coreuiTokens, tokens } from './theme/fluent9';

export type { Display };

export interface LinkStyleProps extends FocusOutlineProps {
  readonly className?: string;
  readonly display?: Display;
  readonly unstyled?: boolean;
  readonly color?: LinkColor;
  readonly activeColor?: LinkColor;
  readonly colorOverride?: FlairColor;
  readonly isOnDarkBackground?: boolean;
  readonly alwaysUnderline?: boolean;
}
export type ThemedLinkStyleProps = Themed<LinkStyleProps>;

export interface ClickableStyleProps extends LinkStyleProps {
  readonly disabled?: boolean;
}

export type LinkColor = 'default' | 'bodyText' | 'blue' | 'theme' | 'white';

const defaultLinkColorMap: Record<LinkColor, FUI9ColorToken> = {
  default: tokens.colorNeutralForeground2Link,
  bodyText: tokens.colorNeutralForeground1,
  blue: coreuiTokens.colorBlueForegroundLink,
  theme: tokens.colorNeutralForeground1,
  white: tokens.colorNeutralForegroundInverted,
};
const brandLinkColorMap: Record<LinkColor, FUI9ColorToken> = {
  default: tokens.colorNeutralForeground2Link,
  bodyText: tokens.colorNeutralForeground1,
  blue: tokens.colorBrandForegroundLink,
  theme: tokens.colorNeutralForeground1,
  white: tokens.colorNeutralForegroundInverted,
};

const getLinkColor = (color: LinkColor, shouldAlignWithBrandColor: boolean) => {
  const colorMap = shouldAlignWithBrandColor ? brandLinkColorMap : defaultLinkColorMap;

  return colorMap[color];
};

export type ThemedClickableStyleProps = Themed<ClickableStyleProps>;
export const useLinkStyles: (styleProps: ThemedClickableStyleProps) => IStyle = ({
  display = 'inline',
  theme,
  color = 'default',
  activeColor = 'blue',
  colorOverride,
  unstyled = false,
  disabled = false,
  alwaysUnderline = false,
}) => {
  const { isOnDarkBackground } = useFlairColorContext();
  const { linksUseBrandColor } = useBrandAccentColorContext();

  const styles = getStyles(
    color,
    activeColor,
    unstyled,
    disabled,
    alwaysUnderline,
    theme,
    linksUseBrandColor,
    colorOverride,
    isOnDarkBackground
  );

  return useMemo(
    () => ({
      display,
      border: 'none',
      background: 'none',
      font: 'inherit',
      margin: 0,
      padding: 0,
      textAlign: 'inherit',
      lineHeight: 'inherit',
      overflow: 'visible',
      ...styles.base,
      selectors: {
        ':hover': styles.active,
        ':hover .y-fakeLink': styles.fakeLinkActive,
        ':hover .y-fakeLink.noUnderline, :focus .y-fakeLink.noUnderline': {
          textDecoration: 'none',
        },
        [`.${IsFocusVisibleClassName} &:focus`]: styles.active,
        [`.${IsFocusVisibleClassName} &:focus .y-fakeLink`]: styles.fakeLinkActive,
        '.y-fakeLink.noLinkColor': {
          color: 'inherit',
        },
        '.y-fakeLink': styles.fakeLink,
      },
    }),
    [display, styles]
  );
};

const getStyles = (
  color: LinkColor,
  activeColor: LinkColor,
  unstyled: boolean,
  disabled: boolean,
  alwaysUnderline: boolean,
  theme: ITheme,
  linksUseBrandColor: boolean,
  colorOverride?: FlairColor,
  isOnDarkBackground?: boolean
) => {
  const { palette } = theme;

  const flairColor = colorOverride && getFlairColor(colorOverride, theme.isInverted, isOnDarkBackground);
  const linkColor = getLinkColor(color, linksUseBrandColor);
  const activeLinkColor = getLinkColor(activeColor, linksUseBrandColor);

  const defaults = {
    base: {
      color:  flairColor ?? linkColor,
      textDecoration: alwaysUnderline ? 'underline' : 'none',
      cursor: 'pointer',
    },
    active: {
      color: flairColor ?? activeLinkColor,
      textDecoration: 'underline',
    },
    fakeLink: {
      color: flairColor ?? linkColor,
    },
    fakeLinkActive: {
      color: flairColor ?? activeLinkColor,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  };

  if (disabled) {
    return {
      base: {
        color: palette.neutralQuaternary,
        textDecoration: 'none',
        cursor: 'auto',
      },
      active: {
        color: palette.neutralQuaternary,
        textDecoration: 'none',
      },
      fakeLink: {
        color: palette.neutralQuaternary,
      },
      fakeLinkActive: {
        color: palette.neutralQuaternary,
        textDecoration: 'none',
        cursor: 'auto',
      },
    };
  }

  if (unstyled) {
    return {
      base: {
        ...defaults.base,
        color: 'inherit',
      },
      active: {
        ...defaults.active,
        textDecoration: 'none',
        color: 'inherit',
      },
      fakeLink: defaults.fakeLink,
      fakeLinkActive: defaults.fakeLinkActive,
    };
  }

  return defaults;
};
