import { UserAdminRoles } from '@ms/yammer-graphql';
import { useExperimentTreatmentWithoutReporting } from '@ms/yammer-hooks/dist/experiments';
import { useFeatureAccessSettings } from '@ms/yammer-hooks/dist/featureAccessSettings';
import { useCurrentSessionUser } from '@ms/yammer-hooks/dist/session';

export const useIsCompactHeaderGeneralEnabled = () =>
  useExperimentTreatmentWithoutReporting('CompactHeaderGeneral') === true;

export const useCanAccessCommunicationsDashboard = () => {
  const isCommunicationsDashboardEnabled = useExperimentTreatmentWithoutReporting('CommunicationsDashboard') === true;
  const accessSettings = useFeatureAccessSettings();
  const { isNetworkAdmin, isVerifiedNetworkAdmin } = useCurrentSessionUser();
  const adminRoles = accessSettings?.viewerAllowedUserAdminRoleAssignments;
  const canAssignUserAdminRoles = !!accessSettings?.viewerCanAssignUserAdminRoles;

  const isUserAdmin =
    isNetworkAdmin ||
    isVerifiedNetworkAdmin ||
    !!adminRoles?.includes(UserAdminRoles.CORPORATE_COMMUNICATOR) ||
    !!adminRoles?.includes(UserAdminRoles.GLOBAL_ADMINISTRATOR) ||
    !!adminRoles?.includes(UserAdminRoles.YAMMER_ADMINISTRATOR);

  return isUserAdmin && canAssignUserAdminRoles && isCommunicationsDashboardEnabled;
};

export const useCanAccessCommunicationsFeaturedConversations = () => useCanAccessCommunicationsDashboard();
export const useCanAccessCommunicationsDashboardLeadersAndAudiences = () => useCanAccessCommunicationsDashboard();
