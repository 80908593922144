import { parse as parseQueryString } from 'querystring';

import { SearchLocationParameters } from '@ms/yammer-search-types';
import {
  searchQueryCampaignIdKey,
  searchQueryEndDateKey,
  searchQueryGroupScopeKey,
  searchQueryNetworkIdKey,
  searchQueryParticipantUserIdsKey,
  searchQueryPostTypeKey,
  searchQueryStartDateKey,
  searchQueryTextKey,
  searchQueryThreadStarterSenderIdKey,
  searchQueryTypeKey,
} from '@ms/yammer-web-support/dist/urls';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { getSearchResultTypeFromTypeUrlParam } from '../urls';

const parseTimestamp = (timestampString: string) => {
  const parsedTimestamp = parseInt(timestampString, 10);

  return isNaN(parsedTimestamp) ? undefined : parsedTimestamp;
};

type UseLocationSearchResultsParameters = () => SearchLocationParameters;
export const useLocationSearchResultsParameters: UseLocationSearchResultsParameters = () => {
  const { search: queryString } = useLocation();

  // eslint-disable-next-line complexity
  return useMemo(() => {
    const {
      [searchQueryTextKey]: textString,
      [searchQueryTypeKey]: typeString,
      [searchQueryGroupScopeKey]: groupIdString,
      [searchQueryCampaignIdKey]: campaignIdString,
      [searchQueryStartDateKey]: startString,
      [searchQueryEndDateKey]: endString,
      [searchQueryNetworkIdKey]: networkIdString,
      [searchQueryParticipantUserIdsKey]: participantUserIdsString,
      [searchQueryThreadStarterSenderIdKey]: threadStarterSenderIdString,
      [searchQueryPostTypeKey]: postTypeString,
    } = parseQueryString(queryString.slice(1));

    const searchText = textString && !Array.isArray(textString) ? textString : undefined;
    const resultType =
      typeString && !Array.isArray(typeString) ? getSearchResultTypeFromTypeUrlParam(typeString) : undefined;
    const groupScope = groupIdString && !Array.isArray(groupIdString) ? groupIdString : undefined;
    const campaignId = campaignIdString && !Array.isArray(campaignIdString) ? campaignIdString : undefined;
    const startDate = startString && !Array.isArray(startString) ? parseTimestamp(startString) : undefined;
    const endDate = endString && !Array.isArray(endString) ? parseTimestamp(endString) : undefined;
    const networkId = networkIdString && !Array.isArray(networkIdString) ? networkIdString : undefined;
    const participantUserIds =
      participantUserIdsString && !Array.isArray(participantUserIdsString)
        ? participantUserIdsString.split(',')
        : undefined;
    const threadStarterSenderId =
      threadStarterSenderIdString && !Array.isArray(threadStarterSenderIdString)
        ? threadStarterSenderIdString
        : undefined;
    const postType = postTypeString && !Array.isArray(postTypeString) ? postTypeString : undefined;

    return {
      ...(searchText && { searchText }),
      ...(resultType && { resultType }),
      ...(groupScope && { groupScope }),
      ...(campaignId && { campaignId }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(networkId && { networkId }),
      ...(participantUserIds && { participantUserIds }),
      ...(threadStarterSenderId && { threadStarterSenderId }),
      ...(postType && { postType }),
    };
  }, [queryString]);
};
