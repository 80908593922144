import { PageRoute } from '@ms/yammer-page-route-telemetry';
import { searchLegacyPath, searchPath } from '@ms/yammer-search-components/dist/urls';
import {
  articleEditPageMatchPath,
  articleNewMatchPath,
  articlePageMatchPath,
  inboxAllPageUrl,
  inboxDirectMessagesPageUrl,
  inboxRootPageUrl,
  inboxUnreadPageUrl,
  leaderSettingsManageLeaderPage,
  m365CopilotAdoptionPageUrl,
  storylineAdminSettingsUrl,
  threadRoutePath,
} from '@ms/yammer-web-support/dist/urls';

export const pageRoutes: PageRoute[] = [
  {
    page: 'ArticleNew',
    path: articleNewMatchPath,
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-article-publisher" */ '../../pages/PageArticleNew'),
  },
  {
    page: 'ArticleEdit',
    path: articleEditPageMatchPath,
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-article-publisher" */ '../../pages/PageArticleEdit'),
  },
  {
    page: 'Article',
    path: articlePageMatchPath,
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-article" */ '../../pages/PageArticle'),
  },
  {
    page: 'CommunicationsDashboard',
    path: '/communications-dashboard',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-communications-dashboard" */ '../../pages/PageDashboardCommunications'),
  },
  {
    page: 'CommsDashboardFeaturedConversations',
    path: '/communications-dashboard/featured-conversations',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-communications-dashboard-featured-conversations" */ '../../pages/PageDashboardFeaturedConversations'
      ),
  },
  {
    page: 'CommunicationsDashboardDigitalSafety',
    path: '/communications-dashboard/digital-safety',
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-communications-dashboard-digital-safety" */ '../../pages/PageDashboardDigitalSafety'
      ),
  },
  {
    page: 'CommunicationsDashboardLeadersAndAudiences',
    path: '/communications-dashboard/leaders-and-audiences',
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-communications-dashboard-leader-and-audiences" */ '../../pages/PageDashboardLeadersAndAudiences'
      ),
  },
  {
    page: 'GroupInsights',
    path: '/groups/:groupId/activity/:groupActivitySubRoute?',
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-group-insights" */ '@ms/yammer-web-components/dist/group/PageGroupInsights'),
  },
  {
    page: 'MediaPostImmersiveView',
    path: '/media-post/:threadId',
    exact: true,
    hero: 'thread',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-media-post-immersive-view" */ '@ms/yammer-web-components/dist/mediaPost/PageMediaPostImmersiveView'
      ),
  },
  {
    page: 'Logout',
    path: '/logout',
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-logout" */ '../../pages/PageLogout'),
  },
  {
    page: 'AMACreate',
    path: '/ama/new/:userId?',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-ama-new" */ '../../pages/PageAskMeAnythingEventCreation'),
  },
  {
    page: 'AMAEdit',
    path: '/ama/:teamsMeetingId/edit',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-ama-edit" */ '../../pages/PageAskMeAnythingEventEditing'),
  },
  {
    page: 'AMAEvent',
    path: '/ama/:teamsMeetingId',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-ama" */ '../../pages/PageAskMeAnything'),
  },
  {
    page: 'AMAEventInReview',
    path: '/ama/:teamsMeetingId/in-review',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-ama" */ '../../pages/PageAskMeAnything'),
  },
  {
    page: 'AMAEventPublished',
    path: '/ama/:teamsMeetingId/published',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-ama" */ '../../pages/PageAskMeAnything'),
  },
  {
    page: 'AMAEventDismissed',
    path: '/ama/:teamsMeetingId/dismissed',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-ama" */ '../../pages/PageAskMeAnything'),
  },
  {
    page: 'AMAUserEvents',
    path: '/users/:userId/events/askmeanything',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-ama-user-events" */ '../../pages/PageAskMeAnythingUserEvents'),
  },
  {
    page: 'AMALeadersEvents',
    path: '/leadership-corner/events/askmeanything',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-ama-leaders-events" */ '../../pages/PageAskMeAnythingLeadersEvents'),
  },
  {
    page: 'LiveEventCreate',
    path: '/events/new/:groupId',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-liveevent-new" */ '@ms/yammer-modules-townhall-event/dist/pages/CreateTownhallEventPage'
      ),
  },
  {
    page: 'LiveEvent',
    path: '/events/:broadcastId/(questions|unanswered)?',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-liveevent" */ '../../pages/PageLiveEvent'),
  },
  {
    page: 'LiveEventEdit',
    path: '/events/:broadcastId/edit',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-liveevent-edit" */ '@ms/yammer-modules-townhall-event/dist/pages/EditTownhallEventPage'
      ),
  },
  {
    page: 'HomeFeed',
    path: '/feed',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-feed" */ '../../pages/PageFeed'),
  },
  {
    page: 'StorylinesHome',
    path: '/storylines',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-storyline-home" */ '../../pages/PageStorylineHome'),
  },
  {
    page: 'UserPendingPosts',
    path: '/users/:userId/pending-posts/:subRoute?',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-drafts" */ '../../pages/PagePendingPosts'),
  },
  {
    page: 'InboxUnread',
    path: inboxUnreadPageUrl,
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-inbox" */ '@ms/yammer-web-components/dist/inbox/PageInbox'),
  },
  {
    page: 'InboxDMs',
    path: inboxDirectMessagesPageUrl,
    exact: true,
    hero: 'feed',
    loader: () =>
      import(/* webpackChunkName: "4-page-inbox" */ '@ms/yammer-web-components/dist/inbox/PageInboxDirectMessages'),
  },
  {
    page: 'InboxAll',
    path: inboxAllPageUrl,
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-inbox" */ '@ms/yammer-web-components/dist/inbox/PageInbox'),
  },
  {
    page: 'InboxFallback',
    path: inboxRootPageUrl,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-inbox-fallback" */ '@ms/yammer-web-components/dist/inbox/PageInboxFallback'),
  },
  {
    page: 'CampaignsDashboard',
    path: '/admin/campaigns-dashboard',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-campaigns-dashboard" */ '../../pages/PageCampaignsDashboard'),
  },
  {
    page: 'CampaignsDashboardWithSubroute',
    path: '/admin/campaigns-dashboard/:campaignsDashboardSubRoute',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-campaigns-dashboard" */ '../../pages/PageCampaignsDashboard'),
  },
  {
    page: 'CampaignsHome',
    path: '/campaigns-hub',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-campaigns-hub" */ '../../pages/PageCampaignsHub'),
  },
  {
    page: 'GroupCreate',
    path: '/create-group',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-create" */ '../../pages/PageGroupCreate'),
  },
  {
    page: 'GroupFeed',
    path: '/groups/:groupId',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-group" */ '../../pages/PageGroup'),
  },
  {
    page: 'GroupCampaigns',
    path: '/groups/:groupId/campaigns',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-campaigns" */ '../../pages/PageGroupCampaigns'),
  },
  {
    page: 'GroupUnseen',
    path: '/groups/:groupId/new',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-group" */ '../../pages/PageGroup'),
  },
  {
    page: 'GroupAll',
    path: '/groups/:groupId/(all|feed)',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-group" */ '../../pages/PageGroup'),
  },
  {
    page: 'GroupQuestions',
    path: '/groups/:groupId/questions',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-group" */ '../../pages/PageGroup'),
  },
  {
    page: 'GroupUnansweredQuestions',
    path: '/groups/:groupId/unanswered',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-group" */ '../../pages/PageGroup'),
  },
  {
    page: 'GroupNoRepliesQuestions',
    path: '/groups/:groupId/norepliesquestions',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-group" */ '../../pages/PageGroup'),
  },
  {
    page: 'GroupAbout',
    path: '/groups/:groupId/about',
    exact: true,
    hero: 'group_about',
    loader: () => import(/* webpackChunkName: "4-page-group" */ '../../pages/PageGroup'),
  },
  {
    page: 'GroupEvents',
    path: '/groups/:groupId/events',
    exact: true,
    hero: 'group_events',
    loader: () => import(/* webpackChunkName: "4-page-group" */ '../../pages/PageGroup'),
  },
  {
    page: 'M365CopilotSuggestedContent',
    path: '/groups/:groupId/m365-copilot-suggested-content',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-m365-copilot-suggested-content" */ '../../pages/PageM365CopilotSuggestedContent'
      ),
  },
  {
    page: 'M365CopilotSuggestedArticle',
    path: '/groups/:groupId/suggested-article',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-suggested-article" */ '../../pages/PageM365CopilotSuggestedArticle'),
  },
  {
    page: 'GroupTopQuestions',
    path: '/groups/:groupId/top-questions',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-top-questions" */ '@ms/yammer-web-components/dist/group/PageGroupTopQuestions'
      ),
  },
  {
    page: 'GroupSettings',
    path: '/groups/:groupId/settings',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-settings" */ '../../pages/PageGroupSettings'),
  },
  {
    page: 'GroupDelete',
    path: '/groups/:groupId/delete',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-delete" */ '../../pages/PageGroupDelete'),
  },
  {
    page: 'GroupInfo',
    path: '/groups/:groupId/info',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-info" */ '../../pages/PageGroupInfo'),
  },
  {
    page: 'GroupInsightsLegacy',
    path: '/groups/:groupId/insights',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-insights-legacy" */ '../../pages/PageGroupInsightsLegacy'),
  },
  {
    page: 'GroupFiles',
    path: '/groups/:groupId/files',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group" */ '../../pages/PageGroup'),
  },
  {
    page: 'AMAGroupEvents',
    path: '/groups/:groupId/events/askmeanything',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-ama-community-events" */ '../../pages/PageAskMeAnythingCommunityEvents'),
  },
  {
    page: 'Campaign',
    path: '/campaigns/:campaignId',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-campaign" */ '../../pages/PageCampaign'),
  },
  {
    page: 'Thread',
    path: threadRoutePath,
    exact: true,
    hero: 'thread',
    loader: () => import(/* webpackChunkName: "4-page-thread" */ '../../pages/PageThread'),
  },
  {
    page: 'UserFeed',
    path: '/users/:userId/:userFeedSubRoute?',
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-user" */ '../../pages/PageUser'),
  },
  {
    page: 'UserAchievements',
    path: '/achievements/:userId',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-user-achievements" */ '@ms/yammer-web-components/dist/user/PageUserAchievements'
      ),
  },
  {
    page: 'TopicFeed',
    path: '/topics/:topicId',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-topic" */ '../../pages/PageTopic'),
  },
  {
    page: 'TopicWithSubroute',
    path: '/topics/:topicId/:topicFeedSubRoute',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-topic" */ '../../pages/PageTopic'),
  },
  {
    page: 'UsagePolicy',
    path: '/usage-policy',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-usagepolicy" */ '../../pages/PageUsagePolicy'),
  },
  {
    page: 'UserSettings',
    path: '/user-settings',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-user-settings" */ '../../pages/PageUserSettings'),
  },
  {
    page: 'UserSettings',
    path: '/user-settings/notifications',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-user-settings-notifications" */ '../../pages/PageUserSettings'),
  },
  {
    page: 'UserSettings',
    path: '/user-settings/notifications/communities-preferences',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-user-settings-notifications-communities" */ '../../pages/PageUserSettings'),
  },
  {
    page: 'UserDelegationSettings',
    path: '/user-settings/delegates',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-delegation-settings" */ '../../pages/PageDelegationSettingsLifecycle'),
  },
  {
    page: 'AdminSettingsStoryline',
    path: storylineAdminSettingsUrl,
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-storyline-admin-settings" */ '../../pages/PageStorylineAdminSettingsLifecycle'
      ),
  },
  {
    page: 'AdminSettingsStorylineNotifications',
    path: '/admin/settings/storyline/advanced',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-storyline-admin-settings-advanced" */ '../../pages/PageStorylineAdminAdvancedSettingsLifecycle'
      ),
  },
  {
    page: 'LeadersManageLegacyRedirect',
    path: '/user-settings/leaders',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-legacy-leader-settings-redirect" */ '../../pages/PageLegacyManageLeadersRedirect'
      ),
  },
  {
    page: 'LeaderManageLegacyRedirect',
    path: leaderSettingsManageLeaderPage,
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-legacy-leader-settings-redirect" */ '../../pages/PageLegacyManageLeaderRedirect'
      ),
  },
  {
    page: 'AdminCopilotAdoption',
    path: m365CopilotAdoptionPageUrl,
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-admin-m365-copilot-adoption" */ '../../pages/PageM365CopilotAdoption'),
  },
  {
    page: 'NetworkAdminSettings',
    path: '/network-admin',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-delegation-settings" */ '../../pages/PageNetworkAdminSettings'),
  },
  {
    page: 'GroupsDiscover',
    path: '/discover-groups',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-discover-groups" */ '../../pages/PageDiscoverGroups'),
  },
  {
    page: 'GroupsDiscoverWithSubroute',
    path: '/discover-groups/:discoverGroupsSubRoute',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-discover-groups" */ '../../pages/PageDiscoverGroups'),
  },
  {
    page: 'Search',
    path: searchPath,
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-search" */ '../../pages/PageSearch'),
  },
  {
    page: 'SearchLegacyRedirect',
    path: searchLegacyPath,
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-search-legacy" */ '@ms/yammer-search-components/dist/components/SearchPage/SearchLegacyRedirect'
      ),
  },
  {
    page: 'LeadershipCorner',
    path: '/leadership-corner',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-leadershipcorner" */ '../../pages/PageLeadershipCorner'),
  },
  {
    page: 'LeaderDashboard',
    path: '/leadership-corner/leader-dashboard',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-leadershipcorner" */ '../../pages/PageLeadershipCorner'),
  },
  {
    page: 'Leaders',
    path: '/leaders',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-leaders-directory" */ '../../pages/PageLeadersDirectory'),
  },
  {
    page: 'LeadersWithSubroute',
    path: '/leaders/:subRoute',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-leaders-directory" */ '../../pages/PageLeadersDirectory'),
  },
  {
    page: 'LeadersCuratedFeed',
    path: '/leadership-corner/featured-conversations',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-leaders-curated-feed" */ '@ms/yammer-web-components/dist/leadersCuratedFeed/PageLeadersCuratedFeedMain'
      ),
  },
  {
    page: 'QuestionThread',
    path: '/questions/:threadId/:topLevelMessageId?/:secondLevelMessageId?',
    exact: true,
    hero: 'thread',
    loader: () => import(/* webpackChunkName: "4-page-question-thread" */ '../../pages/PageQuestionThread'),
  },
  {
    page: 'Questions',
    path: '/questions',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-questions" */ '../../pages/PageQuestions'),
  },
  {
    page: 'QuestionsTopicsBrowse',
    path: '/topics-network-question/browse',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-topic-networkquestion-browse" */ '../../pages/PageTopicNetworkQuestionBrowse'
      ),
  },
  {
    page: 'QuestionsTopicsFeed',
    path: '/topics-network-question/:topicId',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-topic-networkquestion" */ '../../pages/PageTopicNetworkQuestion'),
  },
  {
    page: 'NetworkThemeFeed',
    path: '/network-themes/:themeId',
    exact: true,
    hero: 'feed',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-network-theme" */ '@ms/yammer-web-components/dist/feed/NetworkThemeFeed/PageNetworkThemeFeed'
      ),
  },
  {
    page: 'QuestionsTopicsFeedWithSubroute',
    path: '/topics-network-question/:topicId/:topicFeedSubRoute',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-topic-networkquestion" */ '../../pages/PageTopicNetworkQuestion'),
  },
  {
    page: 'AdminHome',
    path: '/admin',
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-admin-settings" */ '../../pages/PageAdminSettings'),
  },
  {
    page: 'Insights',
    path: '/insights',
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-insights-main" */ '@ms/yammer-web-components/dist/insights/PageInsightsMain'),
  },
  {
    page: 'GroupInfoLegacyRedirect',
    path: '/:networkPermaLink/legacy/groups/:groupId/info',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-info" */ '../../pages/PageGroupInfo'),
  },
  {
    page: 'GroupCreateLegacyRedirect',
    path: '/:networkPermaLink/legacy/create-group',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-create" */ '../../pages/PageGroupCreate'),
  },
  {
    page: 'GroupDiscoverLegacyRedirect',
    path: '/:networkPermaLink/legacy/groups',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-discover-legacy" */ '../../pages/PageGroupDiscoverLegacy'),
  },
  {
    page: 'UserStorylineLegacyRedirect',
    path: '/:networkPermaLink/users/:userId/storyline',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-storyline-legacy-redirect" */ '@ms/yammer-web-components/dist/route/StorylineRouter'
      ),
  },
  {
    page: 'CampaignLegacyRedirect',
    path: '/:networkPermaLink/campaigns/:campaignId',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-campaign-legacy-redirect" */ '@ms/yammer-web-components/dist/route/CampaignRouter'
      ),
  },
  {
    page: 'LegacyRouterURLFormat1',
    path: '/:networkPermaLink/:entityType/:entityId/legacy/*',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-legacyrouter" */ '@ms/yammer-web-components/dist/route/LegacyRouter'),
  },
  {
    page: 'LegacyRouterURLFormat2',
    path: '/:networkPermaLink/legacy/:entityType/:entityId?',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-legacyrouter" */ '@ms/yammer-web-components/dist/route/LegacyRouter'),
  },
  {
    page: 'NetworkThemeThreads',
    path: '/theme-threads/:content',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-network-theme-threads" */ '@ms/yammer-web-components/dist/copilot/PageNetworkThemeThreads'
      ),
  },
  {
    page: 'CuratedThreads',
    path: '/curated-thread-list/:curatedThreadListId/:sourceFileId?/:groupId?',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-curated-threads" */ '../../pages/PageCuratedThreadList'),
  },
  {
    page: 'RootRedirect',
    path: '/',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-root-redirect" */ '../../pages/PageRoot'),
  },
  {
    page: 'LegacyRouteConverter',
    path: '*',
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-legacyrouteconverter" */ '@ms/yammer-web-components/dist/route/LegacyRouteConverter'
      ),
  },
];
